<div class="header">
  <app-header></app-header>
</div>
<div class="router-outlet">
  <router-outlet></router-outlet>
</div>
<!-- <div class="space-before-footer">
  
</div> -->
<div class="footer">
  <app-footer></app-footer>
</div>